/* eslint-disable complexity, react-hooks/exhaustive-deps, @next/next/no-img-element, sonarjs/cognitive-complexity */
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubCatRedirectedBanner from '../../components/SubCatRedirectedBanner';
import VipPaywallDynamic from '../../components/VIpPaywallDynamic';
import VipBanner from '../../components/VipBanner';
import { WellnessBanner } from '../../components/WellnessHeader';
import TimerTo, { TIMER_TYPES } from '../../components/_generic/timer/TimerTo';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import FacetedNavigationContainer from '../../components/category/FacetedNavigationContainer';
import FeaturedSubcatsContainer from '../../components/category/FeaturedSubcatsContainer';
import MerchandisingModule from '../../components/category/MerchandisingModule';
import BottomDeals from '../../components/deal/BottomDeals';
import ConnectedBottomDeals, {
  LAYOUT_TYPES,
} from '../../components/deal/ConnectedBottomDeals';
import {
  dealsGetKeyGenerator,
  fetchDeals,
} from '../../components/deal/ConnectedBottomDeals/helpers';
import TrustBox from '../../components/deal/TrustBox';
import CategoryMainDeal from '../../components/deal/mainDeals/CategoryMainDeal';
import SecondaryDeal from '../../components/deal/secondaryDeal';
import Filters from '../../components/filters/Filters';
import HyperLocalFallbackMessage from '../../components/hyper-local-fallback-mesage/HyperLocalFallbackMessage';
import RefereeAlreadyRegisteredBanner from '../../components/refer-a-friend/RefereeAlreadyRegisteredBanner';
import RefereeWelcomeBanner from '../../components/refer-a-friend/RefereeWelcomeBanner';
import Tiles from '../../components/tiles/Tiles';
import { VIP_BG } from '../../config/constants/images';
import { PAGE_TYPES, VIP_QUALIFIERS } from '../../config/constants/page-types';
import { DESKTOP_PAGE_SIZE } from '../../config/links/links';
import {
  NUMBER_OF_SECONDARY_DEALS_ON_CATEGORY,
  NUMBER_OF_DEALS_FIRST_PAGE_ON_CATEGORY,
  TIMEOUT_CHECK_NOT_FOUND,
  PAGE_CACHE_AGE_SEC,
  SITE_LIVINGSOCIAL_IE,
} from '../../config/setup/setup';
import { NO_FILTER_RESULTS, TYPE_CATEGORY_DEALS } from '../../config/text/text';
import { isOspValid, trackPage, updateDod } from '../../helpers/analytics';
import { isFiltered } from '../../helpers/filters';
import { isFindPage, isFindPath } from '../../helpers/find';
import { isWowcherBrand } from '../../helpers/pages';
import { useIsSSR } from '../../helpers/ssr';
import { parseWowcherPath, pathLocationInfo } from '../../helpers/url';
import DealsLayout from '../../layouts/DealsLayout';
import ThemeContext from '../../providers/ThemeProvider';
import { getClientSideDeals } from '../../redux/actions/clientRender';
import { getGifting } from '../../redux/actions/giftting';
import {
  getServerSideEssentials,
  getServerSideDeals,
} from '../../redux/actions/ssr';
import { getTiles } from '../../redux/actions/tiles';
import { wrapper } from '../../redux/store/store';
import {
  getEarlyBirdDeals,
  getPopularLocations,
} from '../../services/hyperlocal';
import { getPromocode } from '../../services/vouchers';

const Deals = ({ popularLocationsData, url }) => {
  const router = useRouter();
  const [
    mainDeal,
    deals,
    facetedNavigation,
    secondaryDealsArray,
    ssr,
    tiles,
    nomoredeals,
    filterDeals,
    location,
    locations,
  ] = useSelector((state) => [
    state.deals.mainDeal,
    state.deals.deals,
    state.deals.facetedNavigation,
    state.deals.secondaryDealsArray,
    state.deals.ssr,
    state.tiles,
    state.deals.nomore,
    state.filters,
    state.locations.location,
    state.locations.locations,
  ]);

  const additionalContentText = facetedNavigation?.additionalContent;
  const [isAuthCheckFinished] = useSelector((state) => [
    state.user.isAuthCheckFinished,
  ]);
  // without this as a hook, the html between ssr and client doesn't match and
  // generates unexpected html.
  const isSSR = useIsSSR();

  const path = router.asPath;
  const pathUrl = parseWowcherPath(path);
  const isVipHubPage = VIP_QUALIFIERS.includes(pathUrl.pageType);
  const { referralCredit } = useSelector((state) => state.user.userprofile);
  const isVipUser = useSelector((state) => state.user.userprofile.vipUser);
  const { isLocationPage } = pathLocationInfo(path, locations);
  const isShopOrLocal =
    Boolean(pathUrl.pageType === PAGE_TYPES.homepage) || isLocationPage;

  const theme = useContext(ThemeContext);
  // RAF Enabled welcome banner controlled by the env variable (Wowcher only)
  const isWowcher = isWowcherBrand();
  const rafEnabled = JSON.parse(process.env.NEXT_PUBLIC_RAF_ENABLED === 'true');
  const currentNumberTiles = tiles?.tiles?.length || 0;

  const dispatch = useDispatch();
  const [pageNotFound, setPageNotFound] = useState(false);
  const [redirectToCategory, setRedirectToCategory] = useState('');
  const [previousAsPath, setRouterAsPath] = useState('');
  const [promoCode, setPromoCode] = useState({});

  const pageInitial = useRef(0);

  const updateInitialPage = (defaultValue = 0) => {
    pageInitial.current = defaultValue;
  };
  const noMoreFiltersData = useRef(false);
  const noMoreDealsData = useRef(false);
  const updateNoMoreFilters = (defaultValue = 0) => {
    noMoreFiltersData.current = defaultValue;
  };
  const updateNoMoreDeals = (defaultValue = 0) => {
    noMoreDealsData.current = defaultValue;
  };

  const isSubCat = isFindPath(url);
  const isLivingSocialIE =
    process.env.NEXT_PUBLIC_SITE === SITE_LIVINGSOCIAL_IE;

  const [earlyBirdData, setEarlyBirdData] = useState([]);

  const fetchData = async () => {
    const response = await getEarlyBirdDeals();
    setEarlyBirdData(response?.deals);
  };
  const fetchPromoCode = async () => {
    try {
      const result = await getPromocode(pathUrl);
      setPromoCode(result);
    } catch (error) {
      console.error('Promocode endpoint failed with', error);
    }
  };

  useEffect(() => {
    // Only make this call if it is the vip hub page
    if (isVipHubPage) {
      fetchData();
    }
    if (pathUrl.details.subCategory || pathUrl.details.category) {
      fetchPromoCode();
    }
  }, []);

  useEffect(() => {
    if (filterDeals.nomore === noMoreFiltersData.current) return;

    updateNoMoreFilters(filterDeals.nomore);
  }, [filterDeals]);

  useEffect(() => {
    if (nomoredeals === noMoreDealsData.current) return;
    updateNoMoreDeals(nomoredeals);
  }, [nomoredeals]);

  useEffect(() => {
    if (previousAsPath === router.asPath.split('?')[0].toLowerCase()) {
      // we ignore if only the parameters are differents
      return;
    }
    setRouterAsPath(router.asPath.split('?')[0].toLowerCase());
    dispatch(
      getClientSideDeals({
        pageSize: NUMBER_OF_DEALS_FIRST_PAGE_ON_CATEGORY,
        path: router.asPath,
        query: {
          ...router.query,
        },
        secondaryDealsCount: NUMBER_OF_SECONDARY_DEALS_ON_CATEGORY,
      }),
    );
    updateInitialPage(0);

    if (!(router?.query?.rSub === undefined || router?.query?.rSub === null)) {
      setRedirectToCategory(`${pathUrl.pathArray[2]}`);
    }
    dispatch(getTiles(router.asPath, tiles.apiUrl, currentNumberTiles));
  }, [router.asPath]);

  useEffect(() => {
    setTimeout(() => {
      if (
        !ssr &&
        (!mainDeal || Object.keys(mainDeal).length === 0) &&
        (!deals || !deals.length)
      ) {
        // if this is a subcategory, redirect to the category
        if (pathUrl?.pathArray?.length === 4) {
          const url = `/${pathUrl.pathArray[0]}/${pathUrl.pathArray[1]}/${pathUrl.pathArray[2]}?rSub`;
          router.replace(url, url);
        } else {
          setPageNotFound(true);
        }
      }
    }, TIMEOUT_CHECK_NOT_FOUND);
  }, [ssr]);

  useEffect(() => {
    trackPage({
      dealData: mainDeal,
      dealId: mainDeal.id,
      dealLocation: router.query.slug[0],
      location: { name: location.name, shortName: location.shortName },
      products: `;${mainDeal.id};;`,
    });
  }, []);

  useEffect(() => {
    // Get GiftWrap info
    dispatch(getGifting());
  }, [location.shortName]);

  useEffect(() => {
    if (isOspValid()) {
      let pageTrackingData = deals;
      if (mainDeal && pageTrackingData && secondaryDealsArray) {
        pageTrackingData = [mainDeal, ...pageTrackingData];
      }
      const noOfSimilarDeals = pageTrackingData.reduce(
        (count, currentValue) => {
          return (
            count + (currentValue.isSimilarRecommendedDeal === true ? 1 : 0)
          );
        },
        0,
      );

      const noOfPersonalisedDeals = pageTrackingData.reduce(
        (count, currentValue) => {
          return (
            count +
            (currentValue.isPersonalisedRecommendedDeal === true ? 1 : 0)
          );
        },
        0,
      );
      updateDod({ noOfPersonalisedDeals, noOfSimilarDeals });
    }
  }, [deals]);

  const [isFirstPage] = useState(!router.query.page);

  if (pageNotFound) {
    // Import only if needed
    const DynamicComponent = dynamic(() =>
      import('../../components/errors/Form404Block'),
    );

    return <DynamicComponent statusCode={404} />;
  }

  const { details } = parseWowcherPath(router.asPath);

  const isWellnessPage = details.localDealLocation === PAGE_TYPES.wellness;
  if (typeof window !== 'undefined') {
    window.__CWVL = 'categoryPage';
  }

  return (
    <div className="cat-page container-wrapper">
      <>
        {isVipHubPage && (
          <img alt="background-black" className="bg-container" src={VIP_BG} />
        )}
        {isFirstPage && (
          <>
            {Object.keys(promoCode).length > 0 && !isLivingSocialIE && (
              <div className="timer-banner">
                <TimerTo
                  heading={promoCode.banner}
                  id="category-timer-1"
                  type={TIMER_TYPES.category}
                  unixTimestampTo={promoCode.endDate} // TODO: confirm mobile position, get timestamp from new api
                />
              </div>
            )}
            <TrustBox />
            <div className="container head-container">
              {/* Breadcrumb + Main Deal + side menu + hero deals */}
              <div className="row cols main-deal-wrapper">
                {isWellnessPage ? <WellnessBanner theme={theme} /> : <Tiles />}
                {redirectToCategory && (
                  <SubCatRedirectedBanner
                    redirectToCategory={redirectToCategory}
                  />
                )}
                {isVipHubPage ? <VipBanner /> : <div />}
                <BreadCrumb />
                {details?.isHyperlocal ? <HyperLocalFallbackMessage /> : null}
                {!isVipHubPage && (
                  <div
                    className={classNames('cols-20 side-menu item', {
                      'side-menu-shop-and-local': isShopOrLocal,
                    })}
                  >
                    <FacetedNavigationContainer />
                  </div>
                )}

                <div
                  className={`${
                    isVipHubPage ? 'cols-66' : 'cols-50'
                  } main-deal main-deal-item item`}
                >
                  <CategoryMainDeal deal={mainDeal} />
                </div>

                <div
                  className={`${
                    isVipHubPage ? 'cols-33' : 'cols-20'
                  }  hero-deals main-deal-item item`}
                >
                  <div className="cols-display">
                    {secondaryDealsArray.map((deal) => {
                      return (
                        <SecondaryDeal
                          deal={deal}
                          extraclasses={`full-width ${
                            isVipHubPage ? 'small-deal-vip' : 'small-deal'
                          } `}
                          isXS
                          key={deal.id}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>

              <FeaturedSubcatsContainer />
            </div>
          </>
        )}
        {path === `/deals/vip` && isVipUser && (
          <MerchandisingModule
            earlyBirdData={earlyBirdData}
            path={router.asPath}
            position={1}
          />
        )}

        {!isSubCat && <Filters />}
        {/* Bottom deals*/}
        {/* only render the bottom deals for ssr */}
        {isSSR && (
          <BottomDeals
            deals={deals}
            emptyFilters={Boolean(filterDeals && filterDeals.empty)}
            filterDeals={
              filterDeals && filterDeals.deals ? filterDeals.deals : []
            }
            loaded={
              filterDeals && filterDeals.loaded ? filterDeals.loaded : false
            }
            pageInitial={pageInitial.current}
          />
        )}
        <ConnectedBottomDeals
          additionalContentText={additionalContentText}
          emptyText={isFiltered() ? NO_FILTER_RESULTS : ''}
          fetcherFunction={fetchDeals}
          firstPageSize={NUMBER_OF_DEALS_FIRST_PAGE_ON_CATEGORY}
          getKeyGenerator={dealsGetKeyGenerator({
            firstPageSize: NUMBER_OF_DEALS_FIRST_PAGE_ON_CATEGORY,
            isCategoryPage: true,
            pageSize: DESKTOP_PAGE_SIZE,
          })}
          numberSecondaryDeals={NUMBER_OF_SECONDARY_DEALS_ON_CATEGORY}
          pageSize={NUMBER_OF_DEALS_FIRST_PAGE_ON_CATEGORY - 2}
          popularLocationsData={popularLocationsData}
          sideDealsLocal={mainDeal?.display?.sideDealsLocal}
          templateType={
            pathUrl.details.isTravel ? LAYOUT_TYPES.twoByTwo : LAYOUT_TYPES.grid
          }
        />
      </>
      {/* Referee Welcome Banner(For Wowcher Only): Show 1st time When user is coming from Refer a friend */}
      {isWowcher && rafEnabled ? (
        <RefereeWelcomeBanner referralCredit={referralCredit} />
      ) : null}
      {isWowcher ? <RefereeAlreadyRegisteredBanner /> : null}
      {!isVipUser && isVipHubPage && isAuthCheckFinished && (
        <VipPaywallDynamic
          ariaLabelledBy="id-subscribe"
          backdropClassName="popup-bg-white"
          extraClasses="confirmation-popup-container"
          showHeader
          showModal
          size="lg"
          title=""
        />
      )}
      <style jsx>{`
        .cat-page {
          padding-bottom: 60px;
        }
        .bg-container {
          position: fixed;
          height: 100vh;
          width: 100vw;
          object-fit: cover;
          z-index: -1;
          margin-top: -18rem;
        }
        .row {
          width: 100%;
          margin-bottom: 20px;
        }
        .main-deal-wrapper {
          margin-bottom: 0;
          margin-top: ${currentNumberTiles > 0 ? '0px;' : '20px;'};
        }
        .full-row {
          width: 100%;
          margin-bottom: 20px;
        }
        .row-display {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .cols-display {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }
        .cols {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .cols-20 {
          width: 23%;
        }
        .cols-25 {
          width: 25%;
        }
        .cols-33 {
          width: 33%;
        }
        .cols-50 {
          width: 50%;
        }
        .cols-66 {
          width: 66%;
        }
        .main-deal-wrapper :global(.breadcrumb-container) {
          width: 100%;
          margin-bottom: 20px;
        }
        :global(.hero-deals .social-cues-container__main-image__bottom) {
          z-index: 1;
        }
        :global(.trustpilot-widget) {
          padding-top: 18px;
          padding-bottom: ${isVipHubPage ? '18px' : '0px'};
          background: white;
        }
        // iPad
        @media (max-width: ${theme.breakpoints.xlDown}) {
          .head-container {
            width: 668px !important;
            margin: 0 auto;
          }
          .main-deal-wrapper {
            flex-direction: column;
          }
          .hero-deals .cols-display {
            height: auto;
            flex-direction: row;
          }
          .main-deal-wrapper .main-deal {
            order: 2;
            width: 100%;
            margin-bottom: 20px;
            padding-right: 0px;
          }
          .main-deal-wrapper .hero-deals {
            width: 100%;
            order: 5;
          }
          .main-deal-wrapper .side-menu {
            width: 100%;
            order: 4;
            margin-bottom: 20px;
          }
          .main-deal-wrapper :global(.breadcrumb-container) {
            order: 3;
          }
          .main-deal-wrapper .hero-deals :global(.secondary-deal:first-child) {
            margin-right: 20px;
          }
        } // 1199

        .timer-banner {
          text-align: center;
          background-color: ${theme.colors.primary};
          padding: 0.2rem 0.8rem;
        }

        // Mobile
        @media (max-width: ${theme.breakpoints.mdUp}) {
          // 768
          .container {
            padding: 0px;
          }
          .row {
            padding-left: 0px;
            padding-right: 0px;
            margin-left: 0px;
            margin-right: 0px;
          }
          .main-deal-wrapper .hero-deals {
            display: block;
          }
          .main-deal-wrapper .main-deal-item {
            height: auto;
          }
          .item {
            width: 100%;
          }
          .deal {
            height: 300px !important;
            width: 100%;
          }
        }
        @media (max-width: ${theme.breakpoints.smUp}) {
          // side-menu first only for /deals/shop on mobile
          .main-deal-wrapper .side-menu-shop-and-local {
            order: 1;
          }
          .main-deal-wrapper {
            margin: 0;
          }
          .hero-deals .cols-display {
            flex-direction: column;
            margin: 0;
          }
          .main-deal-wrapper .main-deal {
            margin-bottom: 5px;
          }
          .main-deal-wrapper .side-menu {
            margin-bottom: 5px;
          }
          .main-deal-wrapper :global(.breadcrumb-container) {
            padding: 15px 16px 0 16px;
          }
        }
      `}</style>
      <style global jsx>{`
        html,
        body {
          padding: 0;
          margin: 0;
        }

        * {
          box-sizing: border-box;
        }

        .container-wrapper {
          min-height: 100vh;
        }
        .container-wrapper .container {
          padding: 0 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 1110px;
        }

        @media (max-width: ${theme.breakpoints.mdUp}) {
          // 768
          .container-wrapper .container {
            width: 668px;
          }
        } // 728

        @media (max-width: ${theme.breakpoints.smDown}) {
          //575
          .container-wrapper .container {
            width: 100%;
            max-width: 100%;
            padding: 0;
            margin: 0;
          }

          .cols-display .ipad-row {
            width: 100% !important;
          }
          :global(.trustpilot-widget) {
            padding-bottom: 8px;
            padding-top: 4px;
          }
        } //575

        .bottom-deals .card.xl-deal {
          margin-top: 0 !important;
        }

        @media (min-width: ${theme.breakpoints.smUp}) {
          .bottom-deals .card.xl-deal {
            margin-top: 20px !important;
          }
        }
      `}</style>
    </div>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(
  async ({ store, req, query, res }) => {
    if (isFindPage(req?.url)) {
      const cleanUrl = req?.url.includes('/_next/data')
        ? req?.url.replace(/_next\/data\/.+?\/|\.json/g, '')
        : req?.url;

      return {
        redirect: {
          destination: cleanUrl.split('?')[0].replace('/deals', '/find'),
          statusCode: 301,
        },
      };
    }

    const serverSideDeals = await store
      .dispatch(
        getServerSideDeals({
          path: req.url,
          query,
          secondaryDealsCount: NUMBER_OF_SECONDARY_DEALS_ON_CATEGORY,
          ssr: true,
        }),
      )
      .catch((error) => {
        // console.log only visible in Server Side
        console.error(`getServerSideDeals error: ${error}`);
      });

    // core , page, products, navigation
    await store.dispatch(getServerSideEssentials(req.url)).catch((error) => {
      console.error(`getServerSideEssentials , slug deal page: ${error}`);
    });

    const popularLocationsData = await getPopularLocations();

    res.setHeader('Cache-Control', `public, max-age=${PAGE_CACHE_AGE_SEC}`);

    if (
      !req.url.includes('/_next/') &&
      !req.url.includes('near-me') &&
      (!serverSideDeals ||
        serverSideDeals?.length === 0 ||
        !serverSideDeals[0]?.mainDeal ||
        Object.keys(serverSideDeals[0]?.mainDeal).length === 0 ||
        !serverSideDeals[0]?.deals?.length)
    ) {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        popularLocationsData: popularLocationsData || [],
        url: req.url,
      },
    };
  },
);

// Title can not be created here, so we assign a title type
Deals.titletype = TYPE_CATEGORY_DEALS;
// Use the HeaderFooter Layout
Deals.layout = DealsLayout;
// Show the counterdown timer
Deals.countdown = true;

Deals.propTypes = {
  popularLocationsData: PropTypes.array,
  url: PropTypes.string,
};

export default Deals;
